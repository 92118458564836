import './DefaultTemplate.scss';

import Elastic from 'Components/Elastic/Elastic';
import PageHeader from 'Sections/PageHeader/PageHeader';


document.addEventListener("DOMContentLoaded", function() {

	var container = document.querySelector('main.DefaultTemplate');

	if( container ) {

		Elastic.init();

		// -- open photoswipe -- //

			var	button = container.querySelector('.open_gallery');
			var gallery = container.querySelector('.Elastic__gallery');

			if( button && gallery ) {

				button.addEventListener('click', function() {

					gallery.querySelector('.gallery__image').click();

				});

			}

		// -- END -- //

	}

});