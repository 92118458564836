import './Elastic.scss';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {

	init() {
		var t = this;
		// -- photoswipe -- //
			var images = document.querySelectorAll('.ps__image');
			if( images.length > 0  ) {

				images.forEach( function( image,index ) {
					image.addEventListener('click', function() {
						t.initPS( index, images );
					});
				});

			}
		// -- END -- //

	},
	initPS( index , images ) {
		var pswp = document.querySelector('.pswp');
		var items = []; // photoSwipe images
		var options = []; // photoSwipe options
			options.zoomEl = true;
			options.fullscreenEl = false;
			options.shareEl = false;
			options.index = parseInt(index, 10);
		var photoSwipe; // photoSwipe object

		images.forEach( function( image,index ) {
			items.push({
				src: image.getAttribute('data-url'),
				w: image.getAttribute('data-width'),
				h: image.getAttribute('data-height')
			});
		});

		photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);
		photoSwipe.init();
	},
	finalize() {

	}

}